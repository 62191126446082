import {FC, useEffect} from 'react';
import {AdditionalServices} from 'components/page/realEstate/common/additionalServices/components/AdditionalServices';
import {
    SearchResult
} from 'components/page/realEstate/common/advertisements/searchResult/SearchResult';
import {useAdvertisements} from 'components/page/realEstate/agency/detail/hooks/advertisements/useAdvertisements';
import { Box } from '@mui/system';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {LIMIT} from 'components/page/realEstate/common/advertisements/constants/limit';
import {useRouter, useSearchParams} from 'next/navigation';
import { REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
interface IAdvertisements {
    agencyId: string;
    branchType: string;
    agencySlugName: string;
    firstAdvertisements: IAdvertisement[];
    numberOfTotalAdvertisements: number;
    preloadedPage: number;
}

export const Advertisements: FC<IAdvertisements> = ({
    agencyId,
    branchType,
    agencySlugName,
    firstAdvertisements,
    numberOfTotalAdvertisements,
    preloadedPage
}) => {
    const router = useRouter();
    const params = useSearchParams();
    const totalPages = Math.ceil(numberOfTotalAdvertisements / LIMIT);

    const {
        noData,
        fetchNext,
        currentPage,
        advertisements,
        isFetching: isLoading,
    } = useAdvertisements(agencyId, firstAdvertisements, preloadedPage, agencySlugName, branchType);
    const page = params?.get('page');

    useEffect(() => {
        if (preloadedPage > totalPages) {
            fetchNext(totalPages);
        } else if (page === '1') {
            router.replace(REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, branchType as ERouterBranchType, ERouterTabs.ADVERTISEMENT, 1));
        } else if (page !== String(preloadedPage)) {
            router.replace(REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, branchType as ERouterBranchType, ERouterTabs.ADVERTISEMENT, preloadedPage));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SearchResult
                noData={noData}
                isLoading={isLoading}
                totalPages={totalPages}
                onPageChange={fetchNext}
                currentPage={currentPage}
                advertisements={advertisements}
            />
            <Box mb={8}>
                <AdditionalServices/>
            </Box>
        </>
    );
};
