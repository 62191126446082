import {FC} from 'react';
import {Box, Palette} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';

interface ISubtitle {
    subtitle: string,
    helpText?: string
}

export const Subtitle: FC<ISubtitle> = ({subtitle, helpText}) => {
    return <Box display="flex" alignItems="baseline" gap={2}>

        <Text variant="h3" mobileVariant="h5" component="h2" semibold>{subtitle}</Text>

        {helpText && <Text variant="body2" color={(palette: Palette) => palette.labelSecondary}>{helpText}</Text>}
    </Box>;
};
