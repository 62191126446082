import { FC } from 'react';
import dynamic from 'next/dynamic';
import { Box, Container } from '@mui/material';
import { About } from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/about/About';
import { Agents } from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/agents/Agents';
import { IDetailAdvertisementsCountInfo } from 'modules/realEstate/common/interfaces/Detail/IDetailAdvertisementsCountInfo';
import { Portfolio } from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/portfolio/Portfolio';
import { WhereWeAre } from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/whereWeAre/WhereWeAre';
import { ParentAgency } from 'components/page/realEstate/agency/detail/components/tabs/content/about/partials/parentAgency/ParentAgency';
import { IBranch } from 'modules/realEstate/agency/detail/interfaces/IBranch';
import { IAgent } from 'modules/realEstate/agency/detail/interfaces/IAgent';
import { IParentAgency } from 'modules/realEstate/agency/detail/interfaces/agency/partials/IParentAgency';
import { ILocationGps } from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import { IOpenHours } from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';

const Branches = dynamic(() => import('./partials/branches/Branches').then((component) => component.Branches), {
    ssr: false,
});

interface IAboutTab {
    agencyId: string;
    description: string;
    openHours: IOpenHours;
    locationGps: ILocationGps;
    advertisementsCountsInfo: IDetailAdvertisementsCountInfo;
    numberOfTotalBranches: number;
    firstBranches: IBranch[];
    firstAgents: IAgent[];
    numberOfTotalAgents: number;
    parentAgency: IParentAgency;
}

export const AboutTab: FC<IAboutTab> = ({
    agencyId,
    openHours,
    description,
    locationGps,
    advertisementsCountsInfo,
    firstBranches,
    numberOfTotalBranches,
    firstAgents,
    numberOfTotalAgents,
    parentAgency,
}) => {
    return (
        <Container>
            <Box mb={2}>
                <About
                    description={description}
                    advertisementsCountsInfo={advertisementsCountsInfo}
                />
            </Box>

            <Box mb={2}>
                <Agents
                    parentId={agencyId}
                    firstAgents={firstAgents}
                    numberOfTotalAgents={numberOfTotalAgents}
                />
            </Box>

            <Box mb={2}>
                {/* @TODO sem pojde samostatny komponent /detail/components/tabs/about/partials/OfferedServices pre poskytovane sluzby */}
            </Box>

            <Box mb={2}>
                <Portfolio agencyId={agencyId} />
            </Box>

            {parentAgency && (
                <Box mb={2}>
                    {/* TODO - add addressLines and branchDetailUrl when BE is ready */}
                    <ParentAgency
                        parentAgency={parentAgency}
                    />
                </Box>
            )}

            <Box mb={2}>
                <Branches
                    parentId={agencyId}
                    firstBranches={firstBranches}
                    numberOfTotalBranches={numberOfTotalBranches}
                />
            </Box>
            <Box mb={2}>
                {/* @TODO sem pojde samostatny komponent /detail/components/tabs/about/partials/Licences pre licencie*/}
            </Box>

            <Box mb={10}>
                <WhereWeAre locationGps={locationGps} openHours={openHours} />
            </Box>
        </Container>
    );
};
