import { FC } from 'react';
import { TabPanel } from '@mui/lab';
import { ILocationGps } from 'modules/realEstate/agency/detail/interfaces/agency/partials/ILocationGps';
import { AboutTab } from 'components/page/realEstate/agency/detail/components/tabs/content/about/AboutTab';
import { IOpenHours } from 'modules/realEstate/agency/detail/interfaces/agency/partials/openHours/IOpenHours';
import { IDetailAdvertisementsCountInfo } from 'modules/realEstate/common/interfaces/Detail/IDetailAdvertisementsCountInfo';
import { Advertisements } from 'components/page/realEstate/agency/detail/components/tabs/content/advertisements/Advertisements';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { IBranch } from 'modules/realEstate/agency/detail/interfaces/IBranch';
import { IAgent } from 'modules/realEstate/agency/detail/interfaces/IAgent';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import { IParentAgency } from 'modules/realEstate/agency/detail/interfaces/agency/partials/IParentAgency';

interface IContent {
    agencyId: string;
    branchType: string;
    description: string;
    openHours: IOpenHours;
    locationGps: ILocationGps;
    advertisementsCountsInfo: IDetailAdvertisementsCountInfo;
    numberOfTotalBranches: number;
    firstBranches: IBranch[];
    agencySlugName: string;
    firstAgents: IAgent[];
    numberOfTotalAgents: number;
    firstAdvertisements: IAdvertisement[];
    preloadedPage: number;
    parentAgency: IParentAgency;
}

export const Content: FC<IContent> = ({
    agencyId,
    openHours,
    description,
    locationGps,
    branchType,
    advertisementsCountsInfo,
    firstBranches,
    agencySlugName,
    numberOfTotalBranches,
    firstAgents,
    numberOfTotalAgents,
    firstAdvertisements,
    preloadedPage,
    parentAgency
}) => {
    return (
        <>
            <TabPanel value={ERouterTabs.ABOUT} style={{ padding: 0 }}>
                <AboutTab
                    agencyId={agencyId}
                    openHours={openHours}
                    description={description}
                    locationGps={locationGps}
                    parentAgency={parentAgency}
                    advertisementsCountsInfo={advertisementsCountsInfo}
                    firstBranches={firstBranches}
                    numberOfTotalBranches={numberOfTotalBranches}
                    firstAgents={firstAgents}
                    numberOfTotalAgents={numberOfTotalAgents}
                />
            </TabPanel>
            <TabPanel value={ERouterTabs.ADVERTISEMENT} style={{padding: 0}}>
                <Advertisements
                    agencyId={agencyId}
                    branchType={branchType}
                    agencySlugName={agencySlugName}
                    firstAdvertisements={firstAdvertisements}
                    numberOfTotalAdvertisements={advertisementsCountsInfo.totalCount}
                    preloadedPage={preloadedPage}
                />
            </TabPanel>
        </>
    );
};
